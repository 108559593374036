@import "./colors.scss";

.home_form {
    position: relative;
    @media(max-width:991px){
        border-top: 1px solid #fff;
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 50%;
        background-color: $secondary-color;
        height: calc(100% - 20rem);
        border-radius: 0 10rem 10rem 0;
        transform: translateY(-50%);

        @media (max-width: 1199px) {
            width: 55%;
            height: calc(100% - 16rem);
        }
        @media (max-width: 991px) {
            border-radius: 0;
            width: 100%;
            height: 71rem;
            top: 0;
            transform: translate(0);
        }
        @media (max-width: 767px) {
            border-radius: 0;
            width: 100%;
            height: 74rem;
            top: 0;
            transform: translate(0);
        }
        @media (min-width:424px) and (max-width:575px){

            height: 71rem;

        }
    }

    .home_form_left {
        padding: 8rem 15rem 8rem 0;
        position: relative;
        z-index: 9;
        margin-right: 2rem;

        @media (max-width: 1399px) {
            padding: 6rem 12rem 6rem 0;
        }

        @media (max-width: 1199px) {
            padding: 6rem 3rem 6rem 0;
        }
        @media (max-width: 991px) {
            padding: 0rem 0rem 0rem 0;
            margin-right: 0;
        }

        p {
            margin-bottom: 4.5rem;
            @media (max-width: 991px) {
                margin-bottom: 3rem;
            }
        }

        .frm_input_mb {
            margin-bottom: 2rem;

            .frm_cntrl {
                height: 5.4rem;
                border-radius: 1rem;
                padding: 1.7rem;
                font-size: 1.4rem;

                border-color: #e5e5e5 !important;
                outline: none;

                &::placeholder {
                    color: #b4b4b4;
                }

                &:-ms-input-placeholder {
                    color: #b4b4b4;
                }

                &::-ms-input-placeholder {
                    color: #b4b4b4;
                }
            }
        }

        textarea {
            &.frm_cntrl {
                height: 12.7rem !important;
                resize: none;
            }
        }

        button {
            min-width: 23.6rem;
        }
    }

    .bottom_form {
        .sub_heading{
            p{
                margin-top: 3rem;
                margin-bottom: 0;
                a{
                    color: $secondary-color;
                    font-weight: 600;
                    // display: block;
                }
            }
        }
        .contact_img {
            display: flex;
            position: relative;
            @media (max-width: 991px) {
                margin-top: 10rem;
            }

            img {
                border-radius: 1rem 0 0 1rem;
                max-width: calc(100% - 7rem);
                height: 30rem;
                object-fit: cover;
                @media (max-width: 991px) {
                    width: 100%;
                    object-fit: cover;
                }
                @media (max-width: 575px) {
                   height: 20rem;
                }
            }

            span {
                height: 6rem;
                transform: rotate(-90deg);
                position: absolute;
                right: -11.9rem;
                top: 12rem;
                width: 30rem;
                padding: 17px;
                display: flex;
                background-color: $primary-color;
                color: $white-color;
                justify-content: center;
                font-size: 1.9rem;
                font-weight: 500;
                border-radius: 0 0 1rem 1rem;
                text-transform: uppercase;
                letter-spacing: 3px;
                font-weight: 600;
                @media(max-width:575px){
                    right: -6.9rem;
                    top: 7rem;
                    width: 20rem;
                    font-size: 1.6rem;
                }
            }
        }

        h2 {
            margin-top: 4rem;
            @media (max-width: 1199px) {
                margin-top: 2rem;
                margin-bottom: 1rem;
            }
        }

        ul {
            padding-top: 1.5rem;
            @media (max-width: 1199px) {
                padding-top: 0;
            }

            li {
                line-height: 2.8rem;
                display: flex;
                align-items: center;
                margin-top: 3rem;
                max-width: 75%;

                @media (max-width: 1199px) {
                    max-width: 100%;
                    margin-top: 2rem;
                }

                i {
                    background-color: $secondary-color;
                    color: $white-color;
                    border-radius: 0.4rem;
                    width: 4.2rem;
                    height: 4.2rem;
                    min-width: 4.2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 2.2rem;
                }

                a {
                    margin-left: 2rem;

                    &:hover {
                        color: $primary-color;
                    }
                }

                p {
                    margin-left: 2rem;
                }
            }
        }
    }
}
