@import "./colors.scss";

.home_page_about {
    .banner_market_content {
        margin-bottom: 0;
        h2 {
            margin-bottom: 4rem;
            @media (max-width: 1199px) {
                margin-bottom: 2rem;
            }
        }
        p {
            margin-bottom: 6rem;
            @media (max-width: 1199px) {
                margin-bottom: 3rem;
            }
        }
    }

    .banner_market_img {
        img {
            width: 100%;
            max-width: 55rem;
            border-radius: 3rem 0 3rem 6rem;
            @media (max-width: 1599px) {
                height: 58.3rem;
                object-fit: cover;
                object-position: top;
            }
            @media (max-width: 991px) {
                max-width: 100%;
                margin-top: 4rem;
            }
            @media (max-width: 575px) {
                margin-top: 0rem;
                height: 45rem;
                object-position: bottom;
            }
        }
    }
}
