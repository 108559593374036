@import "./colors.scss";

.hmg_service_sec {
    background-image: url("../img/service-hmg-bg.jpg");
    background-size: cover;
    h2 {
        span {
            -webkit-text-stroke: 0.5px #fff;
            color: #003f8f;
        }
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-right: -1rem;
        margin-left: -1rem;
        @media(max-width:767px){
            margin-right: -1.5rem;
            margin-left: -1.5rem;
        }
        li{
            margin-top: 5rem;
            width: calc(20% - 1rem);
            padding-left: 1rem;
            padding-right: 1rem;
            @media(max-width:1199px){
                width: calc(33.33% - 1rem);
                margin-top: 3rem;
            }
            @media(max-width:767px){
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                width: calc(50% - 1rem);
                margin-top: 3rem;
            }
            @media (max-width: 575px) {
                width: calc(100%);
            }
            .hmg_service_item {
                display:block;
                background-color: #fff;
                padding: 3.8rem 2rem;
                border-radius: 1rem;
                height: 100%;
                position: relative;
                @media(max-width:1399px){
                    padding: 2.5rem 1.5rem;
                }
                .hmg_service_item_img_bg {
                    position: absolute;
                    right: 2rem;
                    top: 2rem;
                    transition: all 0.5s;
                    img {
                        width: 140px;
                        height: 140px;
                        object-fit: contain;
                        opacity: 0.05;
                        transition: all 0.5s;
                    }
                }
                .hmg_service_item_img {
                    background-color: #eff3f8;
                    border-radius: 50%;
                    width: 8.2rem;
                    height: 8.2rem;
                    padding: 2rem;
                    margin-bottom: 2.2rem;
                    text-align: center;
                    transition: all 0.5s;
                    img {
                        width: 3.7rem;
                        height: 4.2rem;
                        object-fit: contain;
                    }
                }

                h4 {
                    font-size: 1.9rem;
                    font-weight: 500;
                    margin-bottom: 1.6rem;
                    transition: all 0.5s;
                    @media(max-width:1399px){
                        font-size: 1.7rem;
                        margin-bottom: 1rem;
                    }
                }

                p {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    margin: auto;
                    margin-bottom: 0;
                    // overflow: hidden;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 2;
                    // -webkit-box-orient: vertical;
                    // word-break: break-word;
                    transition: all 0.5s;
                }
                .lear_more_text {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 15rem;
                    text-align: center;
                    display: inline-block;
                    text-transform: uppercase;
                    color: $secondary-color;
                    font-size: 14px;
                    font-weight: 500;
                    opacity: 0;
                    transition: all 0.5s;
                    visibility: hidden;
                    background-color: $secondary-color;
                    padding-top: 0.8rem;
                    padding-bottom: 0.8rem;
                    color: $white-color;
                    border-radius: .3rem;
                    // &:after {
                    //     content: "";
                    //     position: absolute;
                    //     left: 0;
                    //     top: 28px;
                    //     width: 200px;
                    //     background-color: $secondary-color;
                    //     height: 2px;
                    //     transition: all;
                    // }
                    // .arrow_left {
                    //     position: absolute;
                    //     left: -13px;
                    //     top: 12px;
                    //     font-size: 30px;
                    //     line-height: 30px;
                    //     color: $secondary-color;
                    //     transition: all;
                    // }
                    .arrow_right {
                        // position: absolute;
                        // right: -13px;
                        // top: 12px;
                        font-size: 1.8rem;
                        // line-height: 30px;
                        // color: $secondary-color;
                        // transition: all;
                    }
                }
                &:hover {
                    .hmg_service_item_img_bg {
                        opacity: 0;
                    }
                    .hmg_service_item_img {
                        opacity: 0.3;
                    }
                    h4 {
                        opacity: 0.3;
                    }
                    p {
                        opacity: 0.3;
                    }
                    .lear_more_text {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &:nth-child(1), &:nth-child(3), &:nth-child(5){
                .hmg_service_item{
                    background-color: #ebf3ea;
                }
            }

            &:nth-child(2), &:nth-child(4){
                .hmg_service_item{
                    background-color: #e5ebf4;
                }
            }

        }
    }

}
