@import './colors.scss';

.testimonial_page_sec {
    background-color: #f7f7f7;
    .testimonial_item {
        column-count: 2;
        @media(max-width:767px){
            column-count: 1;
        }
        .testimonial_item_list{
            background-color: #fff;
            box-shadow: 0 0 5px 0px #ccc;
            padding: 4.5rem 2.5rem 2.5rem 2.5rem;
            border-radius: 5px;
            margin-bottom: 3rem;
            page-break-inside: avoid;
            break-inside: avoid;
            position: relative;
            @media(max-width:991px){
                padding: 4.5rem 1.5rem 3rem 1.5rem;
            }
            h4 {
                font-size: 2.2rem;
                font-weight: 500;
                margin-bottom: 1rem;
            }
            h5 {
                font-size: 1.6rem;
                font-weight: 500;
                margin-bottom: 1.5rem;
            }
            .desc_sec{
                p {
                    font-size: 1.6rem;
                    line-height: 2.8rem;
                    margin-bottom: 2rem;
                    color: #777;
                    @media(max-width:991px){
                        font-size: 1.5rem;
                        margin-bottom: 1.5rem;
                        line-height: 2.6rem;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }

            }
            ul{
                li{
                    margin-top: 1rem;
                    font-size: 1.5rem;
                    color: #777;
                    line-height: 2rem;
                    margin-left: 2rem;
                    position: relative;
                    @media(max-width:991px){
                        font-size: 1.4rem;
                    }
                    &:before{
                        content: "";
                        position: absolute;
                        top: 0.7rem;
                        left: -2rem;
                        width: .7rem;
                        height: .7rem;
                        background-color: $secondary-color;
                        border-radius: 50%;
                        outline: .1rem solid $secondary-color;
                        outline-offset: 0.2rem;
                    }
                }
            }
            .quote_imgtp {
                position: absolute;
                width: 3rem;
                height: 3rem;
                object-fit: contain;
                left: 2.5rem;
                top: 1rem;
            }
            .quote_imgbtm {
                position: absolute;
                width: 3rem;
                height: 3rem;
                object-fit: contain;
                right: 2.5rem;
                bottom: 1rem;
            }
        }
    }
}
