@import "./colors.scss";

.hmg_banner {
    position: relative;
    height: 90rem;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 70%);
    }

    @media (max-width: 1399px) {
        height: 57rem;
    }

    @media (max-width: 991px) {
        height: auto;
        padding-top: 0rem;
    }

    .hmg_bg_img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    >div {
        position: relative;
        z-index: 7;

        @media (max-width: 991px) {
            padding-top: 15rem;
            padding-bottom: 10rem;
        }

        @media (max-width: 575px) {
            padding-bottom: 5rem;
            padding-top: 10rem;
        }

        >div {
            @media (max-width: 991px) {
                position: relative;
                z-index: 9;
                max-width: 720px;
            }
        }
    }

    .hmg_banner_contnt {
        text-align: center;
        min-height: 32rem;

        @media(max-width:1399px) {
            min-height: 24rem;
        }

        @media (max-width: 991px) {
            position: relative;
            z-index: 99;
            min-height: 25rem;
            padding-left: 0 !important;
        }

        @media(max-width:767px) {
            min-height: 23rem;
        }

        @media(max-width:575px) {
            min-height: 27rem;
        }

        h1 {
            font-size: 4.7rem;
            font-weight: 600;
            line-height: 6.8rem;
            color: #fff;
            margin-bottom: 0;
            text-transform: uppercase;

            @media (max-width: 1599px) {
                font-size: 4.5rem;
                line-height: 6rem;
                max-width: 80%;
                margin: auto;
            }

            @media (max-width: 1399px) {
                font-size: 3.6rem;
                line-height: 4.2rem;
                max-width: 100%;
            }

            @media (max-width: 991px) {
                font-size: 3.5rem;
            }

            @media (max-width: 767px) {
                font-size: 2.8rem;
                line-height: 3.8rem;
            }

            @media (max-width: 575px) {
                font-size: 2.5rem;
                line-height: 3rem;
            }
        }

        h3 {
            font-size: 2.8rem;
            color: #fff;
            margin-bottom: 2rem;
            line-height: 1.5;

            @media(max-width:1399px) {
                font-size: 2.2rem;
                margin-bottom: 1.5rem;
            }

            @media(max-width:1199px) {
                font-size: 2rem;
            }

            @media(max-width:991px) {
                font-size: 1.8rem;
            }

            @media(max-width:767px) {
                font-size: 1.6rem;
                margin-bottom: 1rem;
            }
        }

        p {
            font-size: 2rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            color: #fff;

            @media (max-width: 1599px) {
                font-size: 2rem;
                line-height: 3rem;
            }

            @media (max-width: 1399px) {
                max-width: 85%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 1rem;
                font-size: 1.8rem;
            }

            @media (max-width: 1199px) {
                // max-width: 100%;
                font-size: 1.7rem;
            }

            @media (max-width: 991px) {
                color: $white-color;
                font-size: 1.6rem;
                // max-width: 100%;
            }

            @media(max-width:767px) {
                max-width: 100%;
                margin-top: 1.2rem;
            }

            @media (max-width: 575px) {
                font-size: 1.4rem;
                line-height: 2.8rem;
                margin-top: 1rem;
                margin-bottom: 0;
            }
        }

        @media (max-width: 1399px) {
            padding-left: 1rem;
        }

        .read_more_btn {
            display: block;
            color: $primary-color;
            border: none;
            background-color: transparent;
            padding: 0;
            font-size: 2rem;

            @media(max-width:1199px) {
                font-size: 1.7rem;
            }

            @media(max-width:991px) {
                font-size: 1.6rem;
            }

            @media(max-width:575px) {
                font-size: 1.4rem;
            }
        }
    }

    .btm_btn_sec {
        @media(max-width:1399px) {
            margin-top: 2rem;
        }
    }

    .hmg_banner_btn {
        position: relative;
        z-index: 9;
        background: rgb(72, 72, 73);
        background: linear-gradient(90deg, rgba(72, 72, 73, 1) 0%, rgba(57, 56, 57, 1) 100%);
        border: 2px solid #fff;
        text-align: center;
        min-width: 36.5rem;
        font-size: 1.8rem;
        color: #fff;
        display: inline-block;
        line-height: 6.2rem;
        border-radius: 4rem;

        @media(max-width:1399px) {
            min-width: 34rem;
            line-height: 5rem;
        }

        @media (max-width: 575px) {
            min-width: 31rem;
            font-size: 1.6rem;
            line-height: 5.2rem;
        }

        &:hover {
            background-color: #484849;
            border-color: #484849;
        }
    }

    .branding_logo{
        display: flex;
        justify-content: center;
        margin-top: 5rem;
        gap: 20px;
        // position: absolute;
        // left: 20px;
        // bottom: 20px;

        img{
            max-width: 100px;
        }
    }
}