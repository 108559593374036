@import "./colors.scss";

.banner_innrs {
    background-image: url(../img/bg-hmg-img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 32%;
    padding-top: 23rem;
    padding-bottom: 10rem;
    position: relative;
    @media(max-width:1399px){
        padding-top: 20rem;
        padding-bottom: 5rem;
    }
    @media(max-width:991px){
        padding-top: 15rem;
    }
    @media(max-width:575px){
        padding-top: 10rem;
    }
    &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.7);
    }
    .custom_container{
        position: relative;
        z-index: 1;
    }

    h1 {
        font-size: 5rem;
        font-weight: 600;
        color: #fff;
        line-height: 1.42;
        margin-bottom: 10px;
        @media(max-width:991px){
            font-size: 4rem;
        }
        @media(max-width:767px){
            font-size: 3rem;
        }
    }

    ul {
        display: flex;
        color: #fff;
        li {
            a {
                position: relative;
                // padding-left: 1.5rem;
                // margin-right: 1.5rem;
                // &:before {
                //     content: "";
                //     left: 0;
                //     top: 7px;
                //     width: 0.8rem;
                //     height: 0.8rem;
                //     background-color: #fff;
                //     position: absolute;
                //     border-radius: 50%;
                // }
                &:hover {
                    color: $primary-color;
                }
            }
            &:last-child {
                padding-left: 1.5rem;
                position: relative;
                &::before {
                    content: "";
                    left: 7px;
                    top: 5px;
                    width: 0.2rem;
                    height: 1.5rem;
                    background-color: #fff;
                    position: absolute;
                    border-radius: 50%;
                    transform: rotate(18deg);
                }
            }
        }
    }
}
