@import './colors.scss';

.cta_bg{
    background-color: $secondary-color;
    color: #fff;
    position: relative;
    padding-top: 5.3rem;
    padding-bottom: 6.3rem;
    @media(max-width:991px){
        padding-bottom: 5.3rem;
    }
    &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../img/cta-bg-img.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../img/cta-bg-wave.png');
        background-repeat: no-repeat;
        // background-size: contain;
        background-position: center;
    }
    .custom_container{
        position: relative;
        z-index: 1;
    }

    h2{
        font-size: 4.5rem;
        line-height: 6.5rem;
        margin-bottom: 2.5rem;
        @media(max-width:1399px){
            font-size: 3.4rem;
            line-height: 4.6rem;
        }
        @media(max-width:991px){
            margin-bottom: 1.5rem;
        }
        @media(max-width:767px){
            font-size: 2.8rem;
            line-height: 3.4rem;
        }
    }
    p{
        font-size: 1.6rem;
        line-height: 3.3rem;
        margin-bottom: 0;
        @media(max-width:1199px){
            font-size: 1.5rem;
            line-height: 3rem;
        }
        @media(max-width:991px){
            font-size: 1.4rem;
        }
    }
    .custom_theme_btn{
        padding: 1.75rem 4rem;
        border-radius: 3.3rem;
        border: .2rem solid #fff !important;
        background-color: #2D6AB7!important;
        &:hover{
            background-color: transparent!important;
        }
        @media(max-width:1399px){
            padding: 1.5rem 1.5rem;
            font-size: 1.6rem;
        }
    }
}