@import "./colors.scss";

.theme_ftr {
    background-color: #000d20;
    padding-top: 1rem;
    padding-bottom: 3rem;

    .theme_ftr_innr {
        padding-top: 4rem;

        @media(max-width:575px) {
            padding-top: 2rem;
        }

        img {
            filter: brightness(0) invert(1);
            max-width: 15rem;
            margin-bottom: 2.5rem;

            @media (max-width: 575px) {
                margin-bottom: 1.5rem;
            }
        }

        p {
            font-size: 1.6rem;
            color: $white-color;
            line-height: 3.4rem;

            br {
                @media (max-width: 1199px) {
                    display: none;
                }
            }
        }

        h4 {
            font-size: 2rem;
            margin-bottom: 3.5rem;
            color: $white-color;

            @media(max-width:1199px) {
                margin-bottom: 2.5rem;
            }

            @media (max-width: 575px) {
                margin-bottom: 1.5rem;
            }
        }

        ul {
            li {
                a {
                    display: inline-block;
                    color: #b9b9b9;
                    margin-bottom: 1.6rem;

                    @media (max-width: 575px) {
                        margin-bottom: 1rem;
                    }

                    &:hover {
                        color: $white-color;
                    }
                }
            }
        }
    }

    .ftr_text {
        margin-top: 5.5rem;
        margin-bottom: 0;
        color: #b9b9b9;
        font-size: 1.5rem;

        @media (max-width: 991px) {
            margin-top: 2rem;
        }

        @media (max-width: 575px) {
            font-size: 1.3rem;
            border-top: 1px solid #b9b9b9;
            padding-top: 1.5rem;
        }

        a {
            color: $white-color;
        }
    }

    .branding_logo {
        gap: 10px;
        display: flex;

        img {
            margin: 0;
            max-width: 90px;
            filter: unset;
        }
    }
}