@import "./colors.scss";

.hmg_client_img_sec {
    ul {
        margin-top: 4rem;
        @media (max-width: 767px) {
            justify-content: center;
            margin-top: 1.5rem;
        }

        li {
            flex: 0 0 auto;
            width: 20%;
            @media (max-width: 767px) {
                width: 75%;
                flex: 0 0 75%;
                margin-bottom: 2rem;
                @media (max-width: 575px) {
                    width: 100%;
                    flex: 0 0 100%;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .company_img {
                padding: 6.5rem 1.5rem;
                background-color: $white-color;
                box-shadow: 0 0 25px rgba($color: #000000, $alpha: 0.1);

                @media (max-width: 1599px) {
                    padding: 5rem 2rem;
                    text-align: center;
                }
                @media (max-width: 575px) {
                    padding: 2.5rem 1rem;
                }

                img {
                    width: 21rem;
                    height: 6.5rem;
                    object-fit: contain;

                    @media (max-width: 1599px) {
                        width: 15rem;
                        height: 6rem;
                    }

                    @media (max-width: 1199px) {
                        width: 100%;
                    }
                }
            }

            &:nth-child(1) {
                .company_img {
                    border-radius: 10rem 0 0 10rem;
                    @media (max-width: 767px) {
                        border-radius: 1rem 1rem 1rem 1rem;
                    }
                }
            }

            &:nth-child(2) {
                .company_img {
                    border-radius: 10rem 10rem 0 0;
                    margin-bottom: 4.5rem;

                    @media (max-width: 1399px) {
                        margin-bottom: 3rem;
                    }
                    @media (max-width: 767px) {
                        border-radius: 1rem 1rem 1rem 1rem;
                        margin-bottom: 2rem;
                    }
                }

                .img_radius {
                    border-radius: 0 0 10rem 10rem;
                    @media (max-width: 767px) {
                        border-radius: 1rem 1rem 1rem 1rem;
                    }
                }
            }

            &:nth-child(3) {
                .company_img {
                    border-radius: 10rem 10rem 0 0;
                    margin-bottom: 4.5rem;

                    @media (max-width: 1399px) {
                        margin-bottom: 3rem;
                    }
                    @media (max-width: 767px) {
                        border-radius: 1rem 1rem 1rem 1rem;
                        margin-bottom: 2rem;
                    }
                }

                .radius_none {
                    border-radius: 0;
                    margin-bottom: 4.5rem;

                    @media (max-width: 1399px) {
                        margin-bottom: 3rem;
                    }
                    @media (max-width: 767px) {
                        margin-bottom: 2rem;
                    }
                }

                .img_radius {
                    border-radius: 0 0 10rem 10rem;
                    margin-bottom: 0;
                    @media (max-width: 767px) {
                        border-radius: 1rem 1rem 1rem 1rem;
                    }
                }
            }

            &:nth-child(4) {
                .company_img {
                    border-radius: 10rem 10rem 0 0;
                    margin-bottom: 4.5rem;

                    @media (max-width: 1399px) {
                        margin-bottom: 3rem;
                    }
                    @media (max-width: 767px) {
                        border-radius: 1rem 1rem 1rem 1rem;
                        margin-bottom: 2rem;
                    }
                }

                .img_radius {
                    border-radius: 0 0 10rem 10rem;
                    @media (max-width: 767px) {
                        border-radius: 1rem 1rem 1rem 1rem;
                        margin-bottom: 0;
                    }
                }
            }

            &:nth-child(5) {
                .company_img {
                    border-radius: 0 10rem 10rem 0;
                    @media (max-width: 767px) {
                        border-radius: 1rem 1rem 1rem 1rem;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
