@import './colors.scss';

.about_company{
    .about_company_main{
       padding-top: 50px;
       padding-bottom: 50px;
       @media(max-width:575px){
        padding-top: 3rem;
        padding-bottom: 3rem;
       }
        &:nth-child(2){
            // background-color: $secondary-color;
            // color: $white-color;
        }

    .about_company_card{
        @media(max-width:991px){
            margin-top: 3rem;
        }
        h2{
            font-size: 3.2rem;
            margin-bottom: 20px;
            font-weight: 600;
            color: $black-clr;
            @media(max-width:767px){
                font-size: 2.6rem;
                margin-bottom: 1rem;
            }
            @media(max-width:575px){
                font-size: 2.2rem;
                margin-bottom: 1rem;
            }
        }
        p{
            font-size: 1.6rem;
            line-height: 2;
            color: #777777;
            @media(max-width:767px){
                font-size: 1.5rem;
            }
        }
        ul{
            li{
                margin-top: 1.5rem;
                position: relative;
                padding-left: 2rem;
                font-size: 1.5rem;
                color: #777777;
                &:before{
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 8px;
                    height: 8px;
                    background-color: $white-color;
                    border-radius: 50%;
                }
            }
        }
    }

    &:nth-child(2){
        background-color: $secondary-color;
        color: $white-color;
        .about_company_card{
            h2{
                color: $white-color;
            }
            p{
                color: $white-color;
            }
            ul{
                li{
                    color: #fff;
                }
            }
        }
    }
    &:last-child{
        padding-bottom: 0;
        @media(max-width:991px){
            padding-bottom: 5rem;
        }
    }

    // &:nth-child(1), &:nth-child(3){
    //     @media (max-width:767px) {
    //         padding-top: 0;

    //     }
    // }
}
}