@import '../../assets/css/colors.scss';

.team{
    .heading_sec{
        h2{
            span{
                -webkit-text-stroke: 1px #000;
                color: #fff;
            }
        }
    }
    .team_member_card{
        margin-top: 6rem;
        box-shadow: -34px 36px 34px -31px #e1e1e1;
        position: relative;
        background: #f5f5f5;
        padding: 4rem 3rem;
        border-radius: 1rem;
        @media(max-width:991px){
            box-shadow: 0px 20px 34px -31px #e1e1e1;
            margin-top: 4rem;
        }
        @media(max-width:767px){
          padding: 3rem 2rem;
        }
        img{
            height: 36rem;
            width: 100%;
            object-fit: cover;
            border-radius: 1rem;
            @media(max-width:991px){
                height: 30rem;
            }
            @media(max-width:575px){
                height: auto;
            }
        }
        .content_sec{
            @media(max-width:991px){
                margin-top: 2rem;
                text-align: center;
            }
             h3{
                font-size: 3.5rem;
                color: $secondary-color;
                border-bottom: 2px solid $secondary-color;
                display: inline-block;
                margin-bottom: 1rem;
                padding-bottom: 0.5rem;
             }
             p{
                font-size: 1.5rem;
                line-height: 2.8rem;
                color: #777777;
                margin-bottom:0;
             }
        }
    }
}