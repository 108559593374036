@import "./colors.scss";
.service_sec {
    li.left_content {
        width: 70%;
        @media(max-width:1399px){
            width: 65%;
        }
        @media(max-width:1199px){
            width: 60%;
        }
        @media (max-width: 991px) {
            width: 100%;
        }
    }

    li.right_move_link {
        width: 30%;
        @media(max-width:1399px){
            width: 35%;
        }
        @media(max-width:1199px){
            width: 40%;
        }
        @media (max-width: 991px) {
            // position: fixed;
            // top: 0rem;
            // width: 320px;
            // height: 100vh;
            // background-color: #fff;
            // z-index: 999;
            // box-shadow: 0 0 5px #ddd;
            // transition: all 0.5s;
            display: none;
            @media (max-width: 575px) {
                width: 28rem;
            }
            &.show {
                left: 0;
            }
            &.hide {
                left: -32rem;
                @media (max-width: 575px) {
                    left: -28rem;
                }
            }
        }
    }
    .left_link_sec {
        margin-left: 5rem;
        border: 1px solid #dfdfdf;
        border-radius: 1.2rem;
        transition: all 0.5s;
        position: sticky;
        top: 10rem;
        background-color: #fff;
        @media (max-width: 1399px) {
            margin-left: 4rem;
        }
        @media (max-width: 1024px) {
            margin-left: 1rem;
        }
        @media (max-width: 991px) {
            position: relative;
            margin-left: 0;
            border: none;
            top: 0;
        }
        .btn {
            display: none;
            @media (max-width: 991px) {
                display: inline-block;
                background-color: #fff;
                border: 1px solid #ccc;
                border-left-width: 0;
                padding: 0.8rem 1.5rem;
                position: absolute;
                top: 90%;
                font-weight: 600;
                color: $primary-color;
                right: -4.9rem;
                font-size: 1.8rem;
                border-radius: 0 0.6rem 0.6rem 0;
                @media (max-width: 575px) {
                    padding: 0.5rem 1rem;
                    right: -3.9rem;
                }
            }
        }
        .left_link_sec_header {
            background-color: $secondary-color;
            padding: 2rem 2.2rem;
            border-radius: 1.2rem 1.2rem 0 0;
            h3 {
                font-size: 2rem;
                margin-bottom: 0rem;
                color: #fff;
            }
        }
        ul {
            padding: 1rem 2rem 3rem 2rem;
            @media (max-width: 1199px) {
                padding: 1rem 1rem 3rem 1rem;
            }
            &.main_link {
                li {
                    margin-top: 2rem;
                    margin-left: 1.5rem;
                    @media (max-width: 1199px) {
                        margin-left: 2rem;
                    }
                    a {
                        position: relative;
                        font-size: 1.7rem;
                        @media (max-width: 1199px) {
                            font-size: 1.5rem;
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            width: 0.8rem;
                            height: 0.8rem;
                            background-color: $secondary-color;
                            border-radius: 50%;
                            top: 0.7rem;
                            left: -2rem;
                            @media (max-width: 1199px) {
                                left: -1.7rem;
                            }
                        }
                        &.active {
                            color: $primary-color;
                            &:before {
                                background-color: $primary-color;
                            }
                        }
                    }
                }
                ul {
                    &.inner_link {
                        padding: 0;
                        li {
                            font-size: 1.4rem;
                            margin-left: 1rem;
                            margin-top: 0.8rem;
                            color: #777777;
                            position: relative;
                            &:before {
                                content: "";
                                position: absolute;
                                width: 0.5rem;
                                height: 0.2rem;
                                background-color: $primary-color;
                                top: 1rem;
                                left: -1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .right_content_sec {
        .right_content_sec_inner {
            margin-bottom: 5rem;
            &:last-child {
                margin-bottom: 0;
            }
            @media (max-width: 1399px) {
                margin-bottom: 4rem;
            }
            @media (max-width: 991px) {
                margin-bottom: 3rem;
            }
        }
        h2 {
            font-size: 3.2rem;
            margin-bottom: 2rem;
            @media (max-width: 1399px) {
                font-size: 3rem;
            }
            @media (max-width: 767px) {
                margin-bottom: 2rem;
                font-size: 2.4rem;
            }
            @media (max-width: 575px) {
                margin-bottom: 1.5rem;
                font-size: 2rem;
            }
        }
        p {
            font-size: 2rem;
            margin-bottom: 2rem;
            line-height: 1.8;
            color: #000;
            @media (max-width: 1440px) {
                font-size: 1.8rem;
            }
            @media (max-width: 991px) {
                font-size: 1.6rem;
            }
            @media (max-width: 575px) {
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
        }
        a {
            color: #0000ff;
        }
        ul {
            padding-left: 2rem;
            li {
                font-size: 1.8rem;
                color: #000;
                line-height: 2;
                margin-bottom: 0.5rem;
                position: relative;
                &:last-child{
                    margin-bottom: 0;
                }
                @media (max-width: 1399px) {
                    font-size: 1.7rem;
                }
                @media (max-width: 1199px) {
                    font-size: 1.5rem;
                }
                span {
                    font-weight: 600;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 0.6rem;
                    height: 0.6rem;
                    background-color: $secondary-color;
                    border-radius: 50%;
                    left: -2rem;
                    top: 1.7rem;
                }
                ul {
                    &.inner_list {
                        padding-left: 3.5rem;
                        @media (max-width: 767px) {
                            padding-left: 2.6rem;
                        }
                        li {
                            position: relative;
                            font-size: 1.6rem;
                            @media (max-width: 1199px) {
                                font-size: 1.4rem;
                            }
                            &:after {
                                content: "";
                                position: absolute;
                                width: 0.6rem;
                                height: 0.6rem;
                                border: 0.1rem solid #666;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
