@import "./colors.scss";

.our_expertise {
    position: relative;
    padding-bottom: 5.5rem;
    padding-top: 10rem;
    @media (max-width: 1199px) {
        padding-bottom: 3.5rem;
        padding-top: 8rem;
    }
    @media (max-width: 991px) {
        padding-top: 7rem;
    }

    @media (max-width: 575px) {
        padding-bottom: 1.5rem;
        padding-top: 5rem;
    }

    ul {
        @media (max-width: 991px) {
            padding-top: 12rem;
        }

        @media (max-width: 575px) {
            padding-top: 2rem;
        }

        li {
            margin-bottom: 4.5rem;

            @media (max-width: 991px) {
                margin-bottom: 3.5rem;
            }

            .artier {
                background-color: $white-color;
                padding-top: 4rem;
                padding-bottom: 4rem;
                box-shadow: 0 0 5px #c1baba;
                border-radius: 2rem;

                img {
                    @media (max-width: 1399px) {
                        width: 7rem;
                    }
                }

                h6 {
                    font-size: 2.2rem;
                    margin-top: 3.2rem;
                    margin-bottom: 0;

                    @media (max-width: 1399px) {
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    .bg_position_img {
        img {
            background-color: $secondary-color;
            position: absolute;
            right: 0;
            top: 50%;
            z-index: -1;
            transform: translateY(-55%);
            border-radius: 5rem 0 0 5rem;
            height: 33.2rem;
            width: 50%;

            @media (max-width: 991px) {
                border-radius: 0rem 0 0 0rem;
                height: 65rem;
                width: 100%;
                top: auto;
                bottom: 0;
                transform: translate(0);
            }
        }
    }
}
