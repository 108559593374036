@import "./colors.scss";
.clint_slider {
    background-color: #f2f3f5;

    .slide_say_clint {
        margin-top: 8rem;
        padding: 3.5rem;
        background-color: $white-color;
        position: relative;
        border-radius: 1.2rem;
        margin-bottom: 2rem;
        @media(max-width:1399px){
            margin-bottom: 0;
        }
        .quote_imgtp {
            position: absolute;
            top: -2rem;
            left: 3rem;
            width: 7rem;
            @media(max-width:575px){
                width: 6.4rem;
            }
        }
        .quote_imgbtm {
            position: absolute;
            bottom: -2rem;
            right: 3rem;
            width: 7rem;
            @media(max-width:575px){
                bottom: -3rem;
                width: 6.4rem;
            }
        }

        @media (max-width: 1399px) {
            padding: 3.5rem 3.5rem 3.5rem 4rem;
        }

        @media (max-width: 1199px) {
            margin-top: 4rem;
        }
        @media (max-width: 575px) {
            margin-left: 0;
            padding: 5.5rem 1.5rem 1.5rem 1.5rem;
            text-align: center;
        }

        .slide_img_contant {
            a{
                color: $secondary-color;
                border-bottom: 1px solid $secondary-color;
                font-weight: 500;
            }
            h6 {
                font-size: 1.8rem;
                color: $black-clr;
                text-align: center;
                // color: #fff;
            }

            span {
                font-size: 1.7rem;
                color: #454545;
                // color: #fff;
                margin-bottom: 2.5rem;
                display: block;
                text-align: center;
                @media (max-width: 575px) {
                    margin-bottom: 2rem;
                }
            }

            p {
                font-size: 1.5rem;
                line-height: 3rem;
                color: #454545;
                // color: #fff;
                margin-bottom: 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-word;
                @media (max-width: 575px) {
                    font-size: 1.4rem;
                    line-height: 2.8rem;
                }
            }
            ul {
                li {
                    margin-top: 1.5rem;
                    font-size: 1.4rem;
                    position: relative;
                    padding-left: 1.5rem;
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 1rem;
                        height: 1rem;
                        background-color: #444;
                        border-radius: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}
